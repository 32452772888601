export interface INavbarItem {
  key: string;
  title: string;
  separator?: boolean;
  items?: INavbarItem[]
  icon?: string;
  url?: string;
}

/** MENU */

export const menu: INavbarItem[] = [
  { key: "documentation", title: "Documentation", items: [], url: "https://docs.erpbridge.io?source=admin-website" }
]


/** SETTINGS */
export const settings: INavbarItem[] = [
  // {
  //   key:"settings-global",
  //   title: "views.menu.settings_global",
  //   icon:"cog",
  //   items:[
  //     {
  //       title:"views.menu.settings_global_fo",
  //       key:"/settings/general/frontoffice"
  //     },
  //     {
  //       title:"views.menu.settings_global_bo",
  //       key:"/settings/general/backoffice"
  //     }
  //   ]
  // },
  // {
  //   key:"settings-admin",
  //   icon:"shield",
  //   title: "views.menu.settings_admin",
  //   items:[
  //     {
  //         title:"views.menu.settings_admin_elenco",
  //         key:"/settings/admin/users"
  //       },
  //   ]
  // }
]